<template>
  <div class="editor">
    <editor :disabled="disabled" :editor="editor" v-model="editorData" :config="editorConfig"></editor>
  </div>
</template>

<script>
import ClassicEditor from './core/ckeditor'
import CKEditor from '@ckeditor/ckeditor5-vue2'

const config = require('../../resources/js/config');
export default {
  name: 'ckeditor',
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '请输入内容'
    },
    config: {
      type: Object,
      default: () => {
        return {}
      },
    },
    disabled: {
      type: Boolean,
    }
  },
  data() {
    return {
      // 编辑器组件需要获取编辑器实例
      editor: ClassicEditor,
      editorData: '',
      editorConfig: {
        ...ClassicEditor.defaultConfig,
        placeholder: this.placeholder,
        simpleUpload: {
          uploadUrl: config.UPLOAD_API2
        },
        ...this.config,
      },
    }
  },
  watch: {
    value(val) {
      if (!this.editor) {
        return
      }

      // 外部内容发生变化时，将新值赋予编辑器
      if (val !== this.editorData) {
        this.editorData = this.value
      }
    },
    editorData(val) {
      if (val !== this.value) {
        this.$emit('input', val)
      }
    }
  },
  created() {
    this.editorData = this.value
  },
  components: {
    editor: CKEditor.component
  }
}
</script>

<style scoped lang="scss">
.editor {
  line-height: 1.5;

  &::v-deep .ck-content {
    min-height: 300px;
  }
}
</style>
